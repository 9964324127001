import React, {useState} from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link, graphql } from "gatsby"
import {
  Section,
  SectionTitle,
  BreadCrumb,
  MainPanel,
  LeftPannel,
  RightPannel,
  MarkdownContent,
} from "../components/Section"
import LikeProductCarousel from "../components/LikeProductCarousel"
import QuotePopup from "../components/QuotePopup"
import CallNow from "../components/CallNow"
import BreakpointUp from "../components/Media/BreakpointUp"
import PrimaryForm from "../components/PrimaryForm"
import { StaticImage } from "gatsby-plugin-image"
import ListArrow from "../images/BulletIcon.svg"
import MapStyle from "../components/MapStyle/Cities/GarageMap"
import LocationStyle from "../components/LocationStyle"

const SectionOverlayBg = styled.div`
  height: 100%;
  width: 30%;
  position: absolute;
  top: 0px;
  right: 0;
  &:after {
    position: absolute;
    display: block;
    content: "";
    left: 0;
    top: 0;
    width: 90%;
    height: 100%;
    background: -moz-linear-gradient(
      left,
      rgba(242, 244, 249, 1) 0%,
      rgba(242, 244, 249, 0) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(242, 244, 249, 1) 0%,
      rgba(242, 244, 249, 0) 100%
    );
    background: linear-gradient(
      to right,
      rgba(242, 244, 249, 1) 0%,
      rgba(242, 244, 249, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
`
const SectionOverlay = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const SectionOverlayContent = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 855px;
  & p {
    margin-bottom: 50px;
    @media (min-width: 992px) {
      font-size: 20px;
      line-height: 38px;
    }
    & strong {
      color: #000;
      font-weight: 700;
      line-height: 24px;
    }
  }
  & small {
    display: block;
    color: #0b619b;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
  }

  & ul {
    list-style: none;
    margin: 0 -5px;
    padding: 0;
    & li {
      display: inline-block;
      padding: 0 5px;
      & a {
        color: #000;
        font-weight: 700;
        text-decoration: underline;
        &:hover {
          color: #0b619b;
          text-decoration: none;
        }
      }
    }
  }
`
const LeftContent = styled.div`
  & p {
    @media (min-width: 992px) {
      font-size: 18px;
      line-height: 32px;
      margin-bottom: 40px;
    }
    > a{
      &:hover{
        text-decoration:underline;
      }
    }
  }
`

const SectionEditor = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-right: -15px;
  margin-left: -15px;
  ${BreakpointUp.lg`        
        margin-right: -30px;
        margin-left: -30px; 
    `}
  ul {
    font-weight: 700;
    li {
      padding-left: 40px;
      vertical-align: middle;
      margin: 0;
      line-height: 26px;
      position: relative;
      + li {
        margin-top: 15px;
      }
      &:before {
        content: url(${ListArrow});
        width: 20px;
        height: 20px;
        border-radius: 50%;
        box-shadow: 0 0 0px 6px rgba(23, 27, 39, 0.04);
        vertical-align: middle;
        background: #fff;
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 3px;
      }
    }
  }
  &.section-editor {
    + .section-editor {
      border-top: 1px solid #dde4f0;
      padding-top: 70px;
      margin-top: 70px;
    }
  }
`
const SectionEditorLeft = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`        
        padding-right: 30px;
        padding-left: 30px; 
        flex: 0 0 50%;
        max-width: 50%;
    `}
  & p {
    ${BreakpointUp.lg`
            font-size:20px;
            line-height:38px;
        `}
  }
`
const SectionEditorRight = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`        
        padding-right: 30px;
        padding-left: 30px; 
        flex: 0 0 50%;
        max-width: 50%;
    `}
  & p {
    & small {
      font-size: 20px;
      line-height: 38px;
      display: block;
      font-weight: 700;
      color: #999;
    }
  }
  & strong {
    display: block;
    font-weight: 700;
    color: #000;
    line-height: 28px;
  }
`

const City = ({ data, location, pageContext: { category, city } }) => {
  const [isVisibleQuote, setQuoteVisible] = useState(false);

  const pageData = data.contentfulState
  const cityPageData = city  
  const productCategory = category
  const relatedProductsData = []
    
  data.allContentfulProduct.edges.map((product) => {
     if (product.node.productCategory.name.toLowerCase() === productCategory.split("-").join(" ")) {
       relatedProductsData.push(product.node)
     } 
  })

  if (typeof window !== `undefined`) {
    if (isVisibleQuote === true) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
  }

  return (
    <Layout location={location}>
      <Seo
        title={`Garages ${city} | Metal Garages ${city}, ${pageData.abbreviation} | Prefab Steel Garage Kits ${city}, ${pageData.name}`}
        description={`Metal garages ${city}, ${pageData.abbreviation} - Get high quality, economical, durable steel garages in ${city}, ${pageData.name} as ever when you buy from Coast to Coast Carports. Order Now!`}
      />
      <BreadCrumb>
        <div className="container">
          <Link to="/">Home / </Link>
          <Link
            to={`/${category}-${pageData.name
              .split(" ")
              .join("-")
              .toLowerCase()}-${pageData.abbreviation.toLowerCase()}`}
          >
            {" "}
            {pageData.name} /{" "}
          </Link>
          <span>{city}</span>
        </div>
      </BreadCrumb>
      <Section
        pt="215px"
        pb="90px"
        xpt="120px"
        xpb="60px"
        bgColor="#F2F4F9"
        className="circle-left top-0"
      >
        <div className="container">
          <MainPanel alignItems="center">
            <LeftPannel>
              <LeftContent>
                <h1>{`Metal Garages in ${city}`}</h1>
                <p>
                  Own a fully enclosed steel building to use as a metal garage
                  or guest cottage or backyard workshop or any way you want to!
                  You get an affordable space solution in {city} with Coast to
                  Coast Carports, Inc. Our robust steel building serves you as
                  long as 20 years and provides your property with better resale
                  value. What more do you want? Talk to our representative, and
                  they might be able to help you get even more!
                </p>
                <p>We offer a variety of highly durable metal garages in {city}, perfect for keeping your cars, recreational vehicles, boats, motorhomes, and other valuables safe and secure. We provide custom one-car, two-car, three-car, RV garages, and more to meet all your specific needs. These steel garages in {city} are engineered to be sturdy, durable, and resilient. They can withstand harsh weather conditions such as heavy snow, wind, and rain, as well as resist a variety of threats. Additionally, they can protect your vehicles from the harmful effects of UV rays. These metal garages can serve multiple purposes, such as a storage space, home office, workshop, auto shop, and more. If you need a metal garage, then Coast to Coast Carports is your go-to solution for all of your garage needs.</p>
              </LeftContent>
            </LeftPannel>
            <RightPannel>
              <div className="isSticky">
                <PrimaryForm states={data.allContentfulState.edges}  location = {location} />
              </div>
            </RightPannel>
          </MainPanel>
        </div>
      </Section>
      <Section pt="0" pb="90px" xpb="60px" bgColor="#F2F4F9">
        <div className="container">
          <MapStyle data = {pageData} cityData={cityPageData}  />
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff">
        <div className="container">
          <SectionTitle mb="30px">Explore {city}’s Metal Garages</SectionTitle>
        </div>
        <LikeProductCarousel 
        relatedProductsData={relatedProductsData} 
        openQuoteModal={() => setQuoteVisible(true)} 
        />
      </Section>
      {(pageData.abbreviation === "ID" || pageData.abbreviation === "TX" || pageData.abbreviation === "TN" || pageData.abbreviation === "OR" ) &&  
        <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#DDE4F0">
          <div className="container">
            <LocationStyle city={cityPageData}  />
          </div>
        </Section>
      }
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#F2F4F9">
        <div className="container">
          <SectionEditor className="section-editor">
            <SectionEditorLeft>
              <h2>Roofing Styles for {city} Metal Garages</h2>
              <p>
                Choosing the perfect roof style for your building is one of the
                first, and most important steps you’ll take during the design
                process. And here at Coast to Coast, we offer three roof styles
                for our customers to choose from.
              </p>
            </SectionEditorLeft>
            <SectionEditorRight>
              <ul>
                <li>
                  Regular (Good) – The regular roof is the most economical style
                  in the metal building industry. Its horizontally oriented
                  panels make this roof best suited for areas that do not
                  experience high winds or heavy precipitation.
                </li>
                <li>
                  A-Frame (Better) – Also known as boxed-eave and A-frame
                  horizontal, this roof is a popular choice among customers.
                  With panels running from one end of the building to the other,
                  this building is best suited for areas that may experience a
                  few high winds, but not heavy rain or snowfall.
                </li>
                <li>
                  Vertical (Best) – The vertical roof is designed and engineered
                  with panels that run from side to side, easily channeling all
                  snow, rain, and other debris off the roof and away from the
                  base of the structure. We highly-recommend this roof for areas
                  with harsh weather conditions and lengths of 36’ or more.
                </li>
              </ul>
            </SectionEditorRight>
          </SectionEditor>
          <SectionEditor className="section-editor">
            <SectionEditorLeft>
              <h2>
                Steel Garages in {city}, {pageData.abbreviation}
              </h2>
              <p>
                If you’re looking for a dependable, affordable means of shelter
                in {city}, {pageData.name}, you’ve come to the right place. Our
                metal garages can fulfill all your storage needs and provide
                security to your belongings for only a fraction of the price of
                a wooden structure. You can have peace of mind knowing your
                vehicles, equipment, and other valuable items are safe and
                secure in a garage from Coast to Coast Carports.
              </p>
            </SectionEditorLeft>
            <SectionEditorRight>
              <ul>
                <li>
                  <h3>One Car Garages</h3>
                  <p>
                    If you don’t require a lot of space, a one car garage might
                    be the perfect solution for you. This standard-sized garage
                    is an excellent choice if you only need to shelter one
                    vehicle or a few pieces of smaller equipment.
                  </p>
                </li>
                <li>
                  <h3>Two Car Garages</h3>
                  <p>
                    Our two car garages offer a generous amount of space
                    compared to single-car garages. You have room for at least
                    two vehicles, some larger lawn equipment, or even a repair
                    shop.
                  </p>
                </li>
                <li>
                  <h3>Three Car Garages</h3>
                  <p>
                    With a generous amount of available space, our three car
                    garages offer a variety of possibilities. From sheltering
                    multiple vehicles, to storing agricultural equipment, this
                    structure type can solve all your storage needs.
                  </p>
                </li>
              </ul>
            </SectionEditorRight>
          </SectionEditor>
        </div>
      </Section>
      <Section
        pt="90px"
        pb="90px"
        xpt="60px"
        xpb="60px"
        bgColor="#fff"
        className="circle-right"
      >
        <div className="container mw-1120">
          <MarkdownContent>
            <h2>Installing {city} Metal Garages</h2>
            <p>
              While it may seem overwhelming, Coast to Coast is here to help
              make the process of purchasing a {city} garage feel like a breeze.
              From the first phone call, to designing your dream garage, to the
              day of delivery, we’re here to help.
            </p>
            <p>
              After your papers are signed and you’re ready for install, there
              are a few steps to complete before the day of delivery.
            </p>
            <ul>
              <li>Clear the site of all debris and vegetation</li>
              <li>Level the site</li>
              <li>Obtain all required permits</li>
            </ul>
            <p>
              Once you have completed all site prep and obtained all required
              permits, we can install your structure. Oh, and did we mention
              that we include all delivery and installation services in the
              price of your garage?
            </p>
            <h2>Metal Garages in {city} Prices</h2>
            <p>
              When it comes to the final price of your garage, there are certain
              factors being taken into consideration. Such as your location, the
              manufacturer selling you the product, added customizations, and
              the size of your garage. However, we made it a goal at Coast to
              Coast Carports to make all our products available and affordable
              for all our customers. And to provide additional assistance when
              needed, we also offer two excellent rent-to-own and financing
              programs. For more information regarding these services, you can
              visit our website or contact us at{" "}
              <a href="tel:8666817846" aria-label="(866) 681-7846">(866) 681-7846</a>.
            </p>
            <h2>
              Benefits of Steel Garages in {city}, {pageData.name}
            </h2>
            <p>
              Garages offer customers a convenient way to park their vehicles
              and maybe store some personal belongings, but there are several
              benefits to investing in a metal garage. Keeping reading to find
              out more about what our garages have to offer!
            </p>
            <ul>
              <li>Versatile</li>
              <li>Cost-Effective</li>
              <li>Quick and Easy Installation</li>
              <li>Long-Lasting</li>
              <li>Fire and Pest Resistant</li>
              <li>Fully-Customizable</li>
              <li>Durable</li>
            </ul>
            <h2>Add a Touch of Color to Your {city} Metal Garage</h2>
            <p>
              Choosing an ideal color combination for your metal building
              without knowing what the final product will look like can be
              difficult. But what if we told you that you could see your custom
              structure before spending a dime? With our handy 3D Color Planner,
              it’s never been easier! Choose the perfect set of colors for the
              roof, trim, and walls of your garage and watch it come to life on
              your computer screen. And to make things even easier, you can do
              all this from the comfort of your home.
            </p>
          </MarkdownContent>
        </div>
      </Section>
      <Section
        pt="140px"
        pb="140px"
        xpt="60px"
        xpb="60px"
        bgColor="#F2F4F9"
        className="circle-left"
      >
        <SectionOverlayBg>
          <StaticImage src="../images/purchase-bg.jpg" alt="purchase" />
        </SectionOverlayBg>
        <div className="container">
          <SectionOverlay>
            <SectionOverlayContent>
              <h2>
                Choose Coast to Coast for Your Steel Garage in {city},{" "}
                {pageData.name}
              </h2>
              <p>
                We have been meeting every residential, agricultural, and
                commercial need for customers in {city}, {pageData.abbreviation}{" "}
                since 2001. When you choose to work with us here at Coast to
                Coast Carports, you’re choosing a company that will be here for
                you before, during, and even after the sale of your building.
                Give us a call today at{" "}
                <a href="tel:8666817846" aria-label="(866) 681-7846">(866) 681-7846</a> to get started.
                You can even visit us at one of our locations in Stanfield, OR,
                or Heyburn, ID.
              </p>
              <small>ALSO Explore :</small>
              <ul>
                <li>
                  <Link
                    to={`/metal-carports-${city.replace(/[^a-zA-Z0-9 ]/g, '').split(" ").join("-").toLowerCase()}-${pageData.abbreviation.toLowerCase()}`}
                  >
                    Metal Carports {city} {pageData.abbreviation}
                  </Link>
                  ,
                </li>
                <li>
                  <Link
                    to={`/metal-buildings-${city.replace(/[^a-zA-Z0-9 ]/g, '').split(" ").join("-").toLowerCase()}-${pageData.abbreviation.toLowerCase()}`}
                  >
                    Metal Buildings {city} {pageData.abbreviation}
                  </Link>
                  ,
                </li>
                <li>
                  <Link
                    to={`/metal-rv-covers-${city.replace(/[^a-zA-Z0-9 ]/g, '').split(" ").join("-").toLowerCase()}-${pageData.abbreviation.toLowerCase()}`}
                  >
                    Metal RV Covers {city} {pageData.abbreviation}
                  </Link>
                </li>
              </ul>
            </SectionOverlayContent>
          </SectionOverlay>
        </div>
      </Section>
      <CallNow />
      <QuotePopup
        isVisible={isVisibleQuote}
        location={location}
        onClose={() => setQuoteVisible(false)}
      />
    </Layout>
  )
}

export default City

export const pageQuery = graphql`
  query MetalGaragesCityLandingQuery($id: String!, $productCategory: String!) {
    contentfulState(id: { eq: $id }) {
      name
      abbreviation
      locationForCities {
        cityName
        metalGarageAddress
        metalGarageContactNumber
        metalGarageLocationLink
        metalGarageMapEmbedLink {
          metalGarageMapEmbedLink
        }
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
          locationForCities {
            cityName
            metalGarageAddress
            metalGarageContactNumber
            metalGarageLocationLink
            metalGarageMapEmbedLink {
              metalGarageMapEmbedLink
            }
          }
        }
      }
    }
    allContentfulProduct(
      filter: { productCategory: { name: { eq: $productCategory } } }
    ) {
      edges {
        node {
          id
          productName
          productSku
          productName
          url
          productImages {
            title
            gatsbyImageData
          }
          productCategory {
            name
          }
          width
          height
          length
          price
        }
      }
    }
  }
`
